.MuiLinearProgress-root.mainload {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 999999;
  width: 100%;
}
.stops__popup .leaflet-popup-content {
  width: 260px;
}
.stops__popup .MuiCircularProgress-indeterminate {
  width: 20px!important;
  height: 20px!important;
}
.stops__popup .leaflet-popup-content-wrapper {
  background-color: #E0303B;
  color: #FFF;
  font-weight: bold;
  font-size: 14px;
}  
.stops__popup a.leaflet-popup-close-button {
  color: #FFF;
}
.stops__popup .leaflet-popup-tip {
  background-color: #E0303B;
}
.stops__popup h3 {
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;
}
.stops__popup strong {
  font-size: 15px;
}
.stopline__img {
  height: 30px;
  width: auto;
  float: left;
  margin: 3px 2px 0 0;
}
.stops__popup-nextbuses {
  margin-top: 15px;
}
.stops__popup-nextbus img {
  height: 30px;
  width: auto;
  float: left;
  margin: 3px 8px 2px 0;
}
.stops__popup-nextbus-imgtime {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.stops__popup-nextbus-time {
  font-size: 15px;
  font-weight: bold;
}
.stops__popup-nextbus-desc {
  font-size: 14px;
  margin-bottom: 5px;
}