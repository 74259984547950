#wrapper, .leaflet-container {
  height: 100%;
}


.popupABC .leaflet-popup-content-wrapper {
  background-color: #E0303B;
  color: #FFF;
  font-weight: bold;
  font-size: 14px;
}  
.popupABC a.leaflet-popup-close-button {
  color: #FFF;
}
.popupABC .leaflet-popup-tip {
  background-color: #E0303B;
}