.schedule__wrapper {
  min-height: 100%;
  background-color: #EEE;
  padding: 5px;
}
.shape__wrapper {
  height: 100%;
}
.schedule .MuiCircularProgress-indeterminate {
  width: 20px!important;
  height: 20px!important;
  margin-left: 5px;
  clear: both;
}
.schedule {
  position: relative;
  max-width: 600px;
  margin: 0 auto;
}
.schedule__route-row {
  float: left;
  width: 100%;
  margin-bottom: 15px;
  border-radius: 10px;
}
.schedule__route-row.-open {
  background-color:#FFF;
  border-color: inherit;
  border-top: 8px solid;
}
.schedule__route-name {
  background-color: #FFF;
  border-radius: 10px;
  float: left;
  height: 66px;
  width: calc(97% - 60px);
  box-sizing: border-box;
  margin-right: 3%;
  border-color: inherit;
  border-bottom: 8px solid;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  cursor: hand;
}
.-open .schedule__route-name {
  border: none;
}
.schedule__route-name .route-row-img {
  width: 40px;
  padding: 10px 10px 5px 10px;
}
.schedule__route-name .route-row-img img {
  width: 100%;
  height: auto;
}
.schedule__route-name .route-row-txt {
  color: #E0303B;
  font-weight: bold;
  font-size: 17px;
  margin-bottom: 10px;
}
.schedule__route-map {
  float: left;
  background-color: #FFF;
  border-radius: 10px;
  width: 40px;
  padding: 10px 10px 5px 10px;
  border-color: inherit;
  border-bottom: 8px solid;
  cursor: pointer;
  cursor: hand;
}
.-open .schedule__route-map {
  border: none;
}
.schedule__route-map img {
  width: 100%;
  height: auto;
}

.schedule__route-oneroute {
  float: left;
  width: 100%;
  padding: 0 10px;
  clear: both;
  box-sizing: border-box;
  line-height: 160%;
}
.schedule__route-oneroute > div {
  clear: both;
  margin-top: 10px;
}
.schedule__route-oneroute > div:last-child {
  margin-bottom: 10px;
}
.schedule__route-oneroute h3 {
  margin: 0;
  color: #E0303B;
  font-weight: bold;
  font-size: 17px;
  margin-bottom: 5px;
}
.schedule__route-oneroute span {
  position: relative;
  color: #4e4e4e;
  margin-right: 8px;
  padding-right: 8px;
  font-size: 16px;
  line-height: 13px; 
  white-space: nowrap;
}
.schedule__route-oneroute span:after {
  content: '';
  width: 2px;
  height: 14px;
  background: #4e4e4e;
  position: absolute;
  bottom: 1px;
  right: 0px;
}
.schedule__route-oneroute span:last-child:after {
  background: #FFF;
}
.schedule__route-oneroute .specials {
  margin: 10px -10px 0!important;
  padding: 10px;
  background-color: #6B6764;
  color: #FFF;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}