html, body, #root {
  height: 100%;
}
.clr {
  clear: both;
}

div#fromto__wrapper {
  position: absolute;
  top: 00px;
  width: 100%;
  z-index: 999;
  pointer-events: none;
}
h1 {
  font-size: 20px;
  text-align: center;
  color: #E0303B;
}
div#fromto {
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 500px;
  background-color: #FFF;
  box-sizing: border-box;
  padding: 5px 10px 0px 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
  max-height:500px;
  transition: max-height 0.8s ease;
  pointer-events: all;
}
#fromto__wrapper.-close div#fromto {
  max-height: 50px;
}
#fromto__wrapper .fromto__close {
  position: absolute;
  display: block;
  /* right: calc(2% + 6px); */
  top: 0;
  right: 4px;
  padding: 4px;
  color: #2694d4;
  font-weight: bold;
  cursor: pointer;
  cursor: hand;
}
#fromto__wrapper.-close .fromto__close {
  display: none;
}
#fromto__wrapper .fromto__open {
  position: absolute;
  display: none;
  /* right: calc(2% + 6px); */
  top: 0;
  right: 4px;
  padding: 4px;
  color: #2694d4;
  font-weight: bold;
  cursor: pointer;
  cursor: hand;
}
#fromto__wrapper.-close .fromto__open {
  display: block;
}
.fromto__openClose img {
  width: 100%;
  height: auto;
}
div.fromto__locline.-close {
  max-height: 0px;
}
div.fromto__locline {
  float: left;
  width: 100%;
  padding: 2px 3px;
  box-sizing: border-box;
  overflow: hidden;
  max-height: 50px;
  transition: max-height 0.4s ease;
}
div.fromto__locline input {
  border-bottom: 2px solid #FFF;
}
div.fromto__locline.active input {
  background-color: rgba(224,48,59,0.05);
  border-bottom: 2px solid #E0303B;
}
div.fromto__locleft {
  float: left;
  width: 15%;
  line-height: 40px;
  color: #666;
  font-weight: bold;
}
.MuiFormControl-root.fromto__field {
  float: left;
  width: 81%;
  margin: 4px 2% 4px 2%;
  box-sizing: border-box;
}
.MuiFormControl-root.fromto__field-small {
  float: left;
  width: 38.5%;
  margin: 4px 2% 4px 2%;
  box-sizing: border-box;
}
.MuiFormControl-root.fromto__field-small input {
  color: #667;
  padding-left: 10px;
}
.MuiFormControl-root.fromto__field input {
  padding-left: 10px;
  color: rgba(0,0,0,1);
}
.fromto__plan {
  float: right;
  width: 83%;
  padding: 5px 2% 10px 0;
  box-sizing: border-box;
  text-align: right;
}
.fromto__plan button {
  float: left;
}
.fromto__plan-openclose {
  float:right;
  height: 15px;
  transition: transform 0.4s ease;
  cursor: pointer;
  cursor: hand;
}
.fromto__plan-openclose.-close img {
  transform: rotate(180deg);
}
.fromto__plan-openclose img {
  height: 100%;
  width: auto;
  transition: transform 0.4s ease;
}

#trips__wrapper {
  position: absolute;
  bottom: 0px;
  width: 100%;
  z-index: 9999;
  pointer-events: none;
}