.leafletShape__close {
  position: absolute;
  top: 20px;
  right: 15px;  
  border-radius: 10px;
  padding: 5px 10px;
  background-color: #6b6764;
  z-index: 999999;
  color: #FFF;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  cursor: hand;
}