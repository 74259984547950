.trips__container {
  margin: 0 auto;
  width: 100%;
  max-width: 500px;
  background-color: #FFF;
  box-sizing: border-box;
  padding: 5px 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
  max-height:500px;
  transition: max-height 0.8s ease;
  pointer-events: all;
}
.trips__container.-close {
  max-height: 40px;
}
.trips__error {
  color: #E0303B;
  padding: 10px;
  font-size: 16px;
}
.trips__slider {
  position: relative;
  width: 100%;
}
.trips__slider-header {
  width: 100%;
}
.trips__time {
  position: absolute;
  width: calc(100% - 110px);
  left: 55px;
  top: 6px;
  z-index: 1;
  text-align: center;
  font-weight: bold;
  color: #4e4e4e;
}
.trips__goLeft,
.trips__goRight {
  width: 12px;
  padding: 5px;
  cursor: pointer;
  cursor: hand;
}
.trips__goLeft img,
.trips__goRight img {
  width: 100%;
  height: auto;
}
.trips__goLeft { float: left; }
.trips__goRight { float: right; margin-right: 25px;}
.trips__openClose {
  position: absolute;
  right: 0;
  top: 7px;
  z-index: 9;
  height: 15px;
  transition: transform 0.4s ease;
  cursor: pointer;
  cursor: hand;
}
.trips__container.-close .trips__openClose {
  transform: rotate(180deg);
}
.trips__openClose img {
  height: 100%;
  width: auto;
}
.trips__slider-slides {
    margin: 0 auto;
    width: 100%;
    max-width: 500px;
    background-color: #FFF;
    box-sizing: border-box;
    padding: 0px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    transition: max-height 0.4s ease;
}
.trips__trip-container {
  width: 100%;
  flex-shrink: 0;
  height: 100%;
  transition: transform 0.8s ease-out;
}
.trips__trip-container .legRows {
  width: 100%;
  display: block;
  clear: both;
  border-bottom: 1px dotted #E0303B;
  padding: 3px 0;
  margin-bottom: 3px;
}
.trips__trip-container .legRows:last-child {
  border: none;
}
.trips__trip-container .legRow1,
.trips__trip-container .legRow2 {
  display: flex;
  align-items: center;
}
.trips__trip-container .legRows img {
  height: 30px;
  width: auto;
  margin: 3px 15px 3px 0;
}
.trips__trip-container .legRows .logRows__legMode {
  font-size: 16px;
  font-weight: bold;
  color: #4e4e4e;
}